import { Icon, Label, Tooltip } from '@loveholidays/design-system';
import { IconProps } from '@loveholidays/design-system/lib/components/Icon/Icon';
import { useTranslation } from '@loveholidays/phrasebook';
import React from 'react';

import { OfferHotel } from '@AuroraTypes';
import { useDate } from '@Dates/useDateHook';

type DurationLabelProps = Pick<OfferHotel, 'nights' | 'checkInDate'> & {
  iconSize?: IconProps['size'];
};

export const DurationLabel: React.FC<DurationLabelProps> = ({
  nights,
  checkInDate,
  iconSize = ['20', '16'],
}) => {
  const { t } = useTranslation();
  const { localizedFormat } = useDate();

  return (
    <Tooltip
      content={t('datesShownClarification')}
      sx={{
        display: 'inline-flex',
      }}
    >
      <Label
        variant="small"
        data-id="summary-dates"
        sx={{
          display: 'flex',
          alignItems: 'center',
          textAlign: 'start',
        }}
      >
        <Icon
          name="Content/InputDate"
          size={iconSize}
          sx={{
            marginRight: '3xs',
          }}
        />
        <span
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            columnGap: '4xs',
          }}
        >
          <span>{localizedFormat(checkInDate)}</span>
          <span>∙</span>
          <span>{t('nightsLabel', { count: nights })}</span>
        </span>
      </Label>
    </Tooltip>
  );
};
