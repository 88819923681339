import { createResponsiveConfig } from '@loveholidays/design-system';
import { AllSystemCSSProperties, ResponsiveStyleValue } from '@styled-system/css';
import React, { Fragment } from 'react';

import { DepositSash } from './DepositSash';
import { DiscountSash } from './DiscountSash';
import { MissedItSash } from './MissedItSash';
import { NotAvailableSash } from './NotAvailableSash';
import { ComponentName } from './SearchResultCard';
import { SearchResultSash } from './SearchResultSash';
import { DynamicPackageResult } from '@AuroraTypes';
import { usePassengerPrice } from '@Components/Price/usePassengerPrice';

type SearchResultSashesVariants = 'default' | 'cma';

type Config = Record<
  SearchResultSashesVariants,
  {
    alignItems?: ResponsiveStyleValue<AllSystemCSSProperties['alignItems']>;
    flexDirection?: ResponsiveStyleValue<AllSystemCSSProperties['flexDirection']>;
  }
>;

const config = createResponsiveConfig<Config>({
  default: {},
  cma: {
    alignItems: ['flex-start', 'flex-end'],
    flexDirection: ['row', 'column'],
  },
});

type SearchResultSashesProps = {
  variant?: SearchResultSashesVariants;
  components: Set<ComponentName>;
  offer: DynamicPackageResult;
  hideComponents?: boolean;
  hideBadges?: boolean;
};

export const SearchResultSashes: React.FC<SearchResultSashesProps> = ({
  variant = 'default',
  components,
  offer: { lowestDeposit, pricing, badges },
  hideBadges = false,
  hideComponents = false,
}) => {
  const lowestDepositPrice = usePassengerPrice(lowestDeposit);
  const { alignItems, flexDirection } = config.get(variant);

  return (
    <Fragment>
      {!hideBadges && badges.length !== 0 && (
        <div
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '3xs',
          }}
        >
          {badges.map((badge) => (
            <SearchResultSash
              key={badge.type}
              {...badge}
            />
          ))}
        </div>
      )}
      {!hideComponents && (
        <Fragment>
          {(components.has('missed-it') || components.has('unavailable')) && (
            <div
              sx={{
                display: 'flex',
                flexDirection: flexDirection,
                rowGap: ['3xs', null],
                columnGap: '3xs',
                flexWrap: 'wrap',
              }}
            >
              {components.has('missed-it') && <MissedItSash />}
              {components.has('unavailable') && <NotAvailableSash />}
            </div>
          )}
          <div
            sx={{
              display: 'flex',
              flexDirection: flexDirection,
              rowGap: ['3xs', null],
              columnGap: '3xs',
              flexWrap: 'wrap',
              alignItems: alignItems,
            }}
          >
            {components.has('pricing') && lowestDepositPrice && (
              <DepositSash lowestDeposit={lowestDepositPrice} />
            )}
            {components.has('pricing') && pricing && (
              <DiscountSash
                pricing={pricing}
                isAbsoluteDiscountEnabled
              />
            )}
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};
