import {
  TriggerButton,
  TripadvisorRating,
  GoogleRating,
  GoogleRatingProps,
  TripadvisorRatingProps,
  HolidayCheckRating,
  HolidayCheckRatingProps,
} from '@loveholidays/design-system';
import React from 'react';

import { OpenHotelDetails } from './useHotelDetailsModalOpener';
import { RatingProvider } from '@AuroraTypes';
import { ClassNameProps } from '@ComponentProps';
import { HotelDetailsTabId } from '@Components/HotelDetails/HotelDetailsTypes';

type RatingProps = GoogleRatingProps &
  TripadvisorRatingProps &
  HolidayCheckRatingProps &
  ClassNameProps;

export interface RatingTriggerProps extends RatingProps {
  provider: RatingProvider;
  openHotelDetails: OpenHotelDetails;
}

interface RatingTriggerConfig {
  component: React.FC<RatingProps>;
  triggerName: string;
  hotelsTabId?: HotelDetailsTabId;
}

const RatingMap: Record<RatingTriggerProps['provider'], RatingTriggerConfig> = {
  google: {
    component: GoogleRating,
    triggerName: 'GoogleRating',
  },
  tripadvisor: {
    component: TripadvisorRating,
    triggerName: 'TripadvisorRating',
  },
  holidaycheck: {
    component: HolidayCheckRating,
    triggerName: 'HolidayCheckRating',
    hotelsTabId: HotelDetailsTabId.reviews,
  },
};

export const RatingTrigger: React.FC<RatingTriggerProps> = ({
  provider,
  openHotelDetails,
  ...rest
}) => {
  const Component = RatingMap[provider].component;

  return (
    <TriggerButton
      onTrigger={openHotelDetails({
        hotelDetailsTabId: RatingMap[provider].hotelsTabId! || provider,
        eventTriggerName: RatingMap[provider].triggerName,
      })}
    >
      <Component {...rest} />
    </TriggerButton>
  );
};
