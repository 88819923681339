import { ActionButton, Heading, TriggerEvent } from '@loveholidays/design-system';
import { useTranslation } from '@loveholidays/phrasebook';
import React from 'react';
import { SxStyleProp } from 'theme-ui';

import { SearchResultCard } from './card/SearchResultCard';
import { usePandaPageUrl } from './usePandaPageUrl';
import { ProductTrackingList } from '@AuroraTypes';
import { ClassNameProps } from '@ComponentProps';
import { useQueryParams } from '@Core/helpers/url';
import { useSearchResultsStore, useSearchSelectionStore } from '@Stores/StoreContext';
import { useProductImpressionTracking } from '@Tracking';

const getView = (missedIt: boolean, offer: boolean) => {
  if (missedIt) {
    return 'missed-it';
  }
  if (offer) {
    return 'available';
  }

  return 'unavailable';
};

interface RemoveButtonProps extends ClassNameProps {
  onTrigger: (e: TriggerEvent) => void;
}

const RemoveButton: React.FC<RemoveButtonProps> = ({ className, onTrigger }) => {
  const { t } = useTranslation();

  return (
    <div
      className={className}
      sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}
    >
      <ActionButton
        type="Button"
        icon="Actions/Delete"
        onClick={onTrigger}
        data-id="remove-pinned-result-button"
      >
        {t('remove')}
      </ActionButton>
    </div>
  );
};

interface PinnedSearchResultsProps extends ClassNameProps {
  showRemoveButton?: boolean;
  showHeading?: boolean;
  actionList?: ProductTrackingList;
  favouritesListId?: string;
  isFavouritesReadOnly?: boolean;
}

export const PinnedSearchResults: React.FC<PinnedSearchResultsProps> = ({
  className,
  showRemoveButton = true,
  showHeading = true,
  actionList = 'PinnedResults',
  favouritesListId,
  isFavouritesReadOnly,
}) => {
  const { t } = useTranslation();
  const [fetching, paginating, pinnedResults] = useSearchResultsStore((state) => [
    state.fetching,
    state.paginating,
    state.pinnedResults,
  ]);

  const totalPinnedResults = pinnedResults.length;
  const isLoading = fetching && !paginating;
  const { missedIt } = useQueryParams();

  if (totalPinnedResults === 0 || isLoading) {
    return null;
  }

  const [departureAirports, filters, nights, rooms, removePinnedMasterId, isFamilySearch] =
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useSearchSelectionStore((state) => [
      state.departureAirports,
      state.filters,
      state.nights,
      state.rooms,
      state.removePinnedMasterId,
      state.isFamilySearch(),
    ]);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { getPandaUrlForOffer } = usePandaPageUrl();

  return (
    <section className={className}>
      {(showHeading || showRemoveButton) && (
        <div sx={{ display: 'flex', alignItems: 'center', marginBottom: '2xs' }}>
          {showHeading && (
            <Heading
              variant={['smallbold', 'largebold']}
              as="h2"
              sx={{
                flex: '1',
              }}
            >
              {t('yourSelectedHotel', { count: totalPinnedResults })}
            </Heading>
          )}
          {showRemoveButton && (
            <RemoveButton
              onTrigger={() => removePinnedMasterId(Number(pinnedResults[0].hotel.id))}
              sx={{
                alignSelf: 'flex-end',
              }}
            />
          )}
        </div>
      )}

      {pinnedResults.map(({ hotel, offer }, index) => {
        const hasRemoveButton = index > 0 && showRemoveButton;
        const view = getView(!!missedIt, !!offer);
        const lazyImage = index !== 0;
        const position = index + 1;
        const removeButtonStyle: SxStyleProp = {
          marginBottom: '2xs',
        };
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const ref = useProductImpressionTracking({
          masterId: hotel.id,
          name: hotel.name,
          nights: offer?.hotel.nights,
          inboundFlight: offer?.flights.inbound,
          outboundFlight: offer?.flights.outbound,
          list: actionList,
          position,
          price: offer?.pricing.totalPaidToUs,
          priceIncludingPaidAtHotel: offer?.pricing?.totalIncludingPaidAtHotel,
          xref: offer?.pricing.referral?.xref,
          uuid: offer?.pricing.referral?.uuid,
          view,
        });

        return (
          <section
            key={hotel.id}
            sx={{
              marginBottom: 'l',
              ':last-of-type': {
                marginBottom: 0,
              },
            }}
          >
            {!!hasRemoveButton && (
              <RemoveButton
                onTrigger={() => removePinnedMasterId(Number(hotel.id))}
                sx={removeButtonStyle}
              />
            )}
            {view === 'missed-it' && (
              <SearchResultCard
                data-id="pinned-search-result-card"
                ref={ref}
                components={['hotel-info', 'missed-it']}
                accommodation={hotel}
                lazyImage={lazyImage}
                position={position}
                actionList={actionList}
                favouritesListId={favouritesListId}
                isFavouritesReadOnly={isFavouritesReadOnly}
              />
            )}

            {view === 'unavailable' && (
              <SearchResultCard
                data-id="pinned-search-result-card"
                ref={ref}
                components={['hotel-info', 'unavailable']}
                accommodation={hotel}
                lazyImage={lazyImage}
                position={position}
                actionList={actionList}
                favouritesListId={favouritesListId}
                isFavouritesReadOnly={isFavouritesReadOnly}
              />
            )}

            {view === 'available' && offer && (
              <SearchResultCard
                data-id="pinned-search-result-card"
                ref={ref}
                accommodation={offer.hotel.accommodation}
                offer={offer}
                pandaUrl={getPandaUrlForOffer(offer, {
                  filters,
                  departureAirports,
                  nights,
                  rooms,
                  source: 'srp',
                })}
                lazyImage={lazyImage}
                isFamily={isFamilySearch}
                position={position}
                actionList={actionList}
                favouritesListId={favouritesListId}
                isFavouritesReadOnly={isFavouritesReadOnly}
              />
            )}
          </section>
        );
      })}
    </section>
  );
};
