import { Icon, Label, Tooltip } from '@loveholidays/design-system';
import { IconProps } from '@loveholidays/design-system/lib/components/Icon/Icon';
import { useTranslation } from '@loveholidays/phrasebook';
import React from 'react';

import { OfferHotel } from '@AuroraTypes';

type BoardBasisLabelProps = Pick<OfferHotel, 'boardBasisCode'> & {
  iconSize?: IconProps['size'];
};

export const BoardBasisLabel: React.FC<BoardBasisLabelProps> = ({
  boardBasisCode,
  iconSize = ['20', '16'],
}) => {
  const { t } = useTranslation();

  return (
    <Tooltip
      content={t('boardBasisClarification')}
      sx={{
        display: 'inline-flex',
      }}
    >
      <Label
        variant="small"
        data-id="summary-board-basis"
        sx={{
          display: 'flex',
          alignItems: 'center',
          textAlign: 'start',
        }}
      >
        <Icon
          name="Content/FoodBoard"
          size={iconSize}
          sx={{
            marginRight: '3xs',
          }}
        />
        {t('boardBasis.boardBasis', { context: boardBasisCode })}
      </Label>
    </Tooltip>
  );
};
